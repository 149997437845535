import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/sVuxnngBu1U">
    <SEO title="The Ultimate Cleanse - Greatest Hits" />
  </Layout>
)

export default SermonPost
